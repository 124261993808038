import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';
import {animated, useSpring, interpolate} from 'react-spring';

import Title from "./resources/title.svg";

import styles from "./Search.module.pcss";

const INPUT_TOP_SPACING_VH = 40;

const Search = ({onSearch}) => {
    const [value, setValue] = useState("");
    const [inputStyle, setInputStyle] = useSpring(() => ({top: INPUT_TOP_SPACING_VH, maxWidth: 500}));

    const debouncedSearch = useCallback(debounce(onSearch, 500), []);

    const onChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (newValue.length > 0) {
            setInputStyle({top: 5, maxWidth: 800});
            debouncedSearch(newValue);
        } else {
            setInputStyle({top: INPUT_TOP_SPACING_VH, maxWidth: 500});
            onSearch(newValue);
        }
    };

    return (
        <div className={styles.main}>
            <div className={styles.titleWrapper}>
                <Title className={styles.title} />
            </div>
            <animated.div
                className={styles.inputWrapper}
                style={{
                    ...inputStyle,
                    top: interpolate([inputStyle.top], (top) => `${top}vh`),
                }}
            >
                <input value={value} onChange={onChange} placeholder="Search by manifest name" />
            </animated.div>
            <animated.div
                className={styles.titleMask}
                style={{
                    top: interpolate([inputStyle.top], (top) => `${top}vh`),
                }}
            />
        </div>
    );
};

Search.propTypes = {
    onSearch: PropTypes.func.isRequired,
};

export default Search;
