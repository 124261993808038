import {all, fork} from 'redux-saga/effects';

import {noop} from "utils";

// eslint-disable-next-line func-names
export default (packages) => function* () {
    const sagas = packages.map(((pckg) => {
        if (pckg.saga) {
            return fork(pckg.saga);
        }
        return noop;
    }));

    yield all(sagas);
};
