import React from 'react';
import PropTypes from "prop-types";

import styles from './NotificationLayout.module.pcss';

const NotificationLayout = ({children}) => (
    <div className={styles.main}>
        <div className={styles.wrapper}>
            {children}
        </div>
    </div>
);

NotificationLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default NotificationLayout;
