import {v4 as uuid} from 'uuid';

import {ADD_NOTIFICATION, REMOVE_NOTIFICATION} from "./actions";

export default (state = [], action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return [
                ...state,
                {
                    id: uuid(),
                    message: action.message,
                    variant: action.variant,
                },
            ];
        case REMOVE_NOTIFICATION: {
            return state.filter((notification) => action.notificationId !== notification.id);
        }
        default:
            return state;
    }
};
