import RequestError from "./RequestError";

import {
    PRECOGNITION_FAILED,
    doGet,
    doPost,
    doPut,
    doDelete,
} from "./fetch";

export default {
    PRECOGNITION_FAILED,
    RequestError,
    doGet,
    doPost,
    doPut,
    doDelete,
};
