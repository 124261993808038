import React from 'react';
import PropTypes from 'prop-types';
import {animated, useTransition} from "react-spring";
import {connect} from "react-redux";

import {notificationVariant, NotificationLayout} from "components";

import {getAllNotifications} from "./selectors";
import NotificationController from "./NotificationController";

const NotificationProvider = ({notifications}) => {
    const transitions = useTransition(notifications, ({id}) => id, {
        from: {opacity: 0, y: 0},
        enter: {opacity: 1, y: -50},
        leave: {opacity: 0, y: 0},
    });
    return (
        <NotificationLayout>
            {
                transitions.map(({item: notification, props: animation, key}) => (
                    <animated.div
                        key={key}
                        style={{transform: animation.y.interpolate((y) => `translate3d(0,${y}px,0)`)}}
                    >
                        <NotificationController
                            id={notification.id}
                            message={notification.message}
                            variant={notification.variant}
                        />
                    </animated.div>
                ))
            }
        </NotificationLayout>
    );
};

NotificationProvider.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        variant: PropTypes.oneOf(Object.values(notificationVariant)),
    })).isRequired,

};

const mapStateToProps = (state) => ({
    notifications: getAllNotifications(state),
});

export default connect(mapStateToProps)(NotificationProvider);
