import app from "app";
import fetching from "core/fetching";
import notification from "core/notification";

// if you want to connect package saga and reducer add it into array
// reducer and saga must be exported as default pro packages and also don't forget to export NAME
export default [
    app,
    fetching,
    notification,
];
