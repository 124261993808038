import React from "react";
import {Provider} from "react-redux";

import {Container as App} from "app";
import {NotificationProvider} from "core/notification";

import store from "./store";

const Main = () => (
    <Provider store={store}>
        <NotificationProvider />
        <App />
    </Provider>
);

export default Main;
