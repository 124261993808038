import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import fetching from "core/fetching";
import {Manifests} from "components";

import {Manifest} from "../model";
import {getNextManifestPage} from "../actions";
import {getManifests, getManifestsPagination} from "../selectors";

const ManifestsContainer = ({manifests, isFetching, manifestPagination, getNextPage}) => (
    <Manifests
        manifests={manifests}
        isFetching={isFetching}
        pagination={manifestPagination}
        getNextPage={getNextPage}
    />
);

ManifestsContainer.propTypes = {
    manifests: PropTypes.arrayOf(Manifest.modelPropTypes),
    isFetching: PropTypes.bool,
    manifestPagination: PropTypes.shape({
        page: PropTypes.number,
        size: PropTypes.number,
        total: PropTypes.number,
    }).isRequired,
    getNextPage: PropTypes.func.isRequired,
};

ManifestsContainer.defaultProps = {
    manifests: [],
    isFetching: false,
};

const mapStateToProps = (state) => {
    const isFetchingSelector = fetching.getIsFetchingSelector(fetching.MANIFEST_FETCHING_ID);
    return ({
        manifests: getManifests(state),
        isFetching: isFetchingSelector(state),
        manifestPagination: getManifestsPagination(state),
    });
};

const mapDispatchToProps = ({
    getNextPage: getNextManifestPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManifestsContainer);
