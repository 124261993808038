import React from 'react';

import PropTypes from 'prop-types';

import styles from "./Manifest.module.pcss";

const Manifest = ({name, version}) => (
    <div className={styles.main}>
        <h3>{name}</h3>
        {version && <p className={styles.version}>{`Version: ${version}`}</p>}
    </div>
);

Manifest.propTypes = {
    name: PropTypes.string.isRequired,
    version: PropTypes.string,
};

Manifest.defaultProps = {
    version: null,
};

export default Manifest;
