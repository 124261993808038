import {NAME} from "./constants";
import reducer from "./reducer";
import {addSuccessNotification, addErrorNotification, removeNotification} from "./actions";
import {getAllNotifications} from "./selectors";

export {default as NotificationProvider} from "./NotificationProvider";

export default {
    NAME,
    reducer,
    addSuccessNotification,
    addErrorNotification,
    removeNotification,
    getAllNotifications,
};
