import React from 'react';

import PropTypes from 'prop-types';

import styles from "./PageLayout.module.pcss";

const PageLayout = ({children}) => (
    <div className={styles.main}>{children}</div>
);

PageLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PageLayout;
