import {MANIFEST_FETCHING_ID, NAME} from "./constants";
import reducer from "./reducer";
import {getIsFetchingSelector} from "./selectors";
import {setFetching} from "./actions";

export default {
    NAME,
    reducer,
    getIsFetchingSelector,
    setFetching,
    MANIFEST_FETCHING_ID,
};
