import React from 'react';

import {PageLayout} from "components";

import {ManifestsContainer, SearchContainer} from "./containers";

const Container = () => (
    <PageLayout>
        <SearchContainer />
        <ManifestsContainer />
    </PageLayout>
);

export default Container;
