import React from 'react';
import classnames from "classnames";

import styles from "./ManifestSkeleton.module.pcss";

const ManifestSkeleton = () => (
    <div className={styles.main}>
        <div className={classnames(styles.title, styles.loading)} />
        <div className={classnames(styles.version, styles.loading)} />
    </div>
);

export default ManifestSkeleton;
