import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {Search} from "components";

import {searchManifests as searchManifestsActions} from "../actions";
import {getManifestsPagination} from "../selectors";

const SearchContainer = ({searchManifests, manifestPagination}) => {
    const onSearch = (value) => {
        searchManifests({query: value});
    };
    return <Search onSearch={onSearch} total={manifestPagination.total} />;
};

SearchContainer.propTypes = {
    searchManifests: PropTypes.func.isRequired,
    manifestPagination: PropTypes.shape({
        page: PropTypes.number,
        size: PropTypes.number,
        total: PropTypes.number,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    manifestPagination: getManifestsPagination(state),
});

const mapDispatchToProps = ({
    searchManifests: searchManifestsActions,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
