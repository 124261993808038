import {notificationVariant} from "components";

import {NAME} from './constants';

export const ADD_NOTIFICATION = `${NAME}/ADD_NOTIFICATION`;
export const REMOVE_NOTIFICATION = `${NAME}/REMOVE_NOTIFICATION`;

const createNotificationAction = (variant) => (message) => ({
    type: ADD_NOTIFICATION,
    variant,
    message,
});

export const addSuccessNotification = createNotificationAction(notificationVariant.SUCCESS);
export const addErrorNotification = createNotificationAction(notificationVariant.ERROR);
export const removeNotification = (notificationId) => ({
    type: REMOVE_NOTIFICATION,
    notificationId,
});
