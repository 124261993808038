import {createStore, applyMiddleware, compose, combineReducers} from "redux";
import createSagaMiddleware from 'redux-saga';
import {batch} from 'react-redux';

import {createAppSaga, getAppReducers} from "core";
import packages from "packages";

// Redux middleware which converts a dispatched array of actions to a batch action.
const reduxBatchMiddleware = () => (next) => (action) => {
    if (Array.isArray(action)) {
        batch(() => action.forEach(next));
    } else {
        next(action);
    }
};

const sagaContext = {};
const sagaMiddleware = createSagaMiddleware({context: sagaContext});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({latency: 10})
    : compose;

const middleware = composeEnhancers(applyMiddleware(reduxBatchMiddleware, sagaMiddleware, reduxBatchMiddleware));

const store = createStore(combineReducers(getAppReducers(packages)), middleware);

sagaMiddleware.run(createAppSaga(packages));

export default store;
