export const identity = (x) => (x);
export const noop = () => {};

export const createSimpleReducer = (defaultState, type, property) => (state = defaultState, action) => (
    type === action.type ? action.payload[property] : state
);

export const createSimpleAction = (type) => () => ({type});

// create template for BE response
export const createModel = (fromServer = identity, toServer = identity()) => ({
    fromServer,
    toServer,
    fromServerList: (items) => items.map(fromServer),
    toServerList: (items) => items.map(toServer),
    modelPropTypes: undefined,
});

const exists = (currentValue) => !!currentValue;
export const removeEmpty = (array) => array.filter(exists);

export const createSagaAction = (actionName) => {
    const REQUEST = `${actionName}/REQUEST`;
    const SUCCESS = `${actionName}/SUCCESS`;
    const FAILURE = `${actionName}/FAILURE`;
    const action = (payload) => ({type: REQUEST, payload});
    action.success = (payload) => ({type: SUCCESS, payload});
    action.failure = (payload) => ({type: FAILURE, payload});
    action.REQUEST = REQUEST;
    action.SUCCESS = SUCCESS;
    action.FAILURE = FAILURE;
    return action;
};
