import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';

import {notificationVariant} from './constants';

import styles from './Notification.module.pcss';

const variantClass = {
    [notificationVariant.SUCCESS]: styles.success,
    [notificationVariant.ERROR]: styles.error,
};

const Notification = ({message, onClose, variant}) => (
    <div
        className={classnames(styles.notification, variantClass[variant])}
        onClick={onClose}
        onKeyPress={onClose}
    >
        {message}
    </div>
);

Notification.propTypes = {
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(Object.values(notificationVariant)),
};

Notification.defaultProps = {
    variant: notificationVariant.SUCCESS,
};

export default Notification;
