import PropTypes from 'prop-types';

import {createModel} from 'utils';

const manifest = createModel(
    (item) => ({
        id: item.id,
        name: item.name,
        version: item.version,
    }),
);

manifest.modelPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    version: PropTypes.string,
});

export default manifest;
