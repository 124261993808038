import superagent from 'superagent';
import {CANCEL} from 'redux-saga';
import {call} from 'redux-saga/effects';

import {identity} from "utils";

import RequestError from "./RequestError";

export const PRECOGNITION_FAILED = 412;

const execute = (request) => {
    const promise = request.then(
        (response) => response.body,
        (error) => {
            if (error.status) {
                // Apparently, superagent returns (json?) parse error here. Original response is in field "rawResponse".
                if (error.rawResponse) {
                    /* eslint-disable no-console */
                    console.warn(error.rawResponse);
                    /* eslint-disable no-console */
                }
                throw new RequestError(error, request.method !== "GET");
            } else {
                // some weird errors here, plus terminated by browser
                console.error(error);
                throw new RequestError({status: 500, response: {body: "Unexpected error."}}, request.method !== "GET");
            }
        },
    );
    promise[CANCEL] = () => request.abort();
    return promise;
};

const wrapExecute = function* (request, resultProcessMethod) {
    // eslint-disable-next-line no-useless-catch
    try {
        const result = yield call(execute, request);
        return result && result.content
            ? {
                ...result,
                content: resultProcessMethod(result.content),
            } : resultProcessMethod(result);
    } catch (error) {
        throw error;
    }
};

export const doGet = function* (url, queryParams, resultProcessMethod = identity, accepted = 'json') {
    const request = superagent
        .get(url)
        .set('credentials', 'same-origin')
        .set('Accept', 'application/json')
        .query(queryParams)
        .accept(accepted);
    return yield* wrapExecute(request, resultProcessMethod);
};

export const doPut = function* (url, body, requestProcessMethod = identity, resultProcessMethod = identity, queryParams = {}) {
    const request = superagent
        .put(url)
        .set('credentials', 'same-origin')
        .set('Accept', 'application/json')
        .send(requestProcessMethod(body))
        .query(queryParams)
        .type('json')
        .accept('json');
    return yield* wrapExecute(request, resultProcessMethod);
};

export const doPost = function* (url, body, requestProcessMethod = identity, resultProcessMethod = identity, queryParams = {}) {
    const request = superagent
        .post(url)
        .set('credentials', 'same-origin')
        .set('Accept', 'application/json')
        .send(requestProcessMethod(body))
        .query(queryParams);
    return yield* wrapExecute(request, resultProcessMethod);
};

export const doDelete = function* (url, resultProcessMethod = identity) {
    const request = superagent
        .delete(url)
        .set('credentials', 'same-origin')
        .set('Accept', 'application/json')
        .accept('json');
    return yield* wrapExecute(request, resultProcessMethod);
};
