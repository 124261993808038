import {combineReducers} from 'redux';
import {getNextManifestPage, searchManifests} from "./actions";
import {PAGE_SIZE} from "./constants";

const manifests = (state = [], {type, payload}) => {
    switch (type) {
        case searchManifests.SUCCESS: {
            return payload.content;
        }
        case getNextManifestPage.SUCCESS: {
            return [...state, ...payload.content];
        }
        default: return state;
    }
};

const manifestsPaginationDefault = {page: 0, size: PAGE_SIZE, total: 0, query: ""};
const manifestsPagination = (state = manifestsPaginationDefault, {type, payload}) => {
    switch (type) {
        case getNextManifestPage.SUCCESS:
        case searchManifests.SUCCESS: {
            return {page: payload.page, size: payload.size, total: payload.total, query: payload.query};
        }
        case searchManifests.FAILURE: {
            return manifestsPaginationDefault;
        }
        default: return state;
    }
};

export default combineReducers({
    manifests,
    manifestsPagination,
});
