import {put, call, all, takeLatest, select} from 'redux-saga/effects';

import fetching from "core/fetching";
import notification from "core/notification";

import {manifestApi} from './serverApi';
import {getManifestsPagination} from "./selectors";
import {
    searchManifests as searchManifestsAction,
    getNextManifestPage,
} from "./actions";
import {PAGE_SIZE} from "./constants";

// eslint-disable-next-line func-names
export default function* () {
    yield all([
        takeLatest(searchManifestsAction.REQUEST, fetchManifests),
        takeLatest(getNextManifestPage.REQUEST, fetchMoreManifests),
    ]);
}

function* fetchManifests({payload: {query}}) {
    const pagination = yield select(getManifestsPagination);
    try {
        if (query.length > 0) {
            const firstPage = 0;
            yield put(fetching.setFetching(fetching.MANIFEST_FETCHING_ID, true));
            const manifests = yield call(manifestApi.fetchManifests, query, firstPage, pagination.size);
            yield put(searchManifestsAction.success(manifests));
        } else {
            yield put(searchManifestsAction.success({content: [], page: 0, size: PAGE_SIZE, total: 0, query: ""}));
        }
    } catch (e) {
        console.error(e);
        yield put(notification.addErrorNotification("There was error with manifest fetching."));
        yield put(searchManifestsAction.failure());
    } finally {
        yield put(fetching.setFetching(fetching.MANIFEST_FETCHING_ID, false));
    }
}

function* fetchMoreManifests() {
    try {
        const pagination = yield select(getManifestsPagination);
        const nextPage = pagination.page + 1;
        yield put(fetching.setFetching(fetching.MANIFEST_FETCHING_ID, true));
        const manifests = yield call(manifestApi.fetchManifests, pagination.query, nextPage, pagination.size);
        yield put(getNextManifestPage.success(manifests));
    } catch (e) {
        console.error(e);
        yield put(searchManifestsAction.failure());
    } finally {
        yield put(fetching.setFetching(fetching.MANIFEST_FETCHING_ID, false));
    }
}
