import {NAME} from "./constants";
import saga from "./saga";
import reducer from "./reducer";

export {default as Container} from "./Container";

export default {
    NAME,
    saga,
    reducer,
};
