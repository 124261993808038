import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import {notificationVariant, Notification} from "components";

import {NOTIFICATION_TIMEOUT} from "./constants";
import {removeNotification} from "./actions";

const NotificationController = ({message, onClose, variant}) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, NOTIFICATION_TIMEOUT);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Notification
            message={message}
            onClose={onClose}
            variant={variant}
        />
    );
};

NotificationController.propTypes = {
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(Object.values(notificationVariant)),
};

NotificationController.defaultProps = {
    variant: notificationVariant.SUCCESS,
};

const mapDispatchToProps = (dispatch, {id}) => ({
    onClose: () => dispatch(removeNotification(id)),
});

export default connect(null, mapDispatchToProps)(NotificationController);
