import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import {render} from "react-dom";

import Main from "./Main";

import "./index.pcss";
import "./variables.pcss";

render(<Main />, document.getElementById("app"));
